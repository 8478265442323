<template>
  <div id="app">

    <el-row :gutter="24">

      <el-col :sm="6" style="margin-top: 10px;">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>
              <i class="fa fa-address-card-o"></i>
              運營人員
            </span>
            <span style="float: right;" v-if="$store.state.userInfo.adminId == 1">
               <el-link type="primary" @click="link('admin:member')">管理</el-link>
            </span>
          </div>
          <div class="text item">
            <span class="ivu-numeral" style="font-size: 20px;">
              {{data.memberInfo.size}}<span style="font-size: 14px;"> 位</span>
            </span>
          </div>
        </el-card>
      </el-col>

      <el-col :sm="6"  style="margin-top: 10px;">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>
              <i class="fa fa-history"></i>
              計劃任務
            </span>
            <span style="float: right;" v-if="$store.state.userInfo.adminId == 1">
              <el-link type="primary" @click="link('crontab:item')">管理</el-link>
            </span>
          </div>
          <div class="text item">
            <span class="ivu-numeral" style="font-size: 20px;">
              {{data.crontabInfo.size}}<span style="font-size: 14px;"> 任務</span>
            </span>
          </div>
        </el-card>
      </el-col>

      <el-col  :sm="12" style="margin-top: 10px;">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fa fa-laptop"></i>
            <span> 系統信息</span>
            <span style="float: right;" v-if="$store.state.userInfo.adminId == 1">
              <el-button type="success" size="mini" disabled>更新</el-button>
              <el-button type="primary" size="mini" disabled>重啟</el-button>
              <el-button  size="mini" disabled>初始化</el-button>
            </span>
          </div>
          <div class="text item">
            <p>版本：v1.0.0 beta  <span style="color: #F56C6C;">( 暫時停止線上更新服務 )</span></p>
            <p>授權：無限制</p>
          </div>
        </el-card>
      </el-col>

      <el-col :md="{span: 24}" style="margin-top: 10px;" v-if="$store.state.userInfo.adminId == 1">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="fa fa-calendar"></i>
            <span> 操作日誌</span>
            <span style="float: right;">
              <el-link type="primary"  @click="link('admin:logs:action')">查看更多</el-link>
            </span>
          </div>
          <div class="text item">
            <template>
              <el-table
                v-loading="loading"
                :data="data.actionInfo.list"
                style="width: 100%">
                  <el-table-column prop="account" label="用戶名稱" width="90"></el-table-column>
                  <el-table-column prop="clientIP" label="登入IP"></el-table-column>
                  <el-table-column prop="location" label="登入位置"></el-table-column>
                  <el-table-column prop="browser" label="瀏覽器"></el-table-column>
                  <el-table-column prop="clientOS" label="操作系統"></el-table-column>
              </el-table>
            </template>
          </div>
        </el-card>
      </el-col>

    </el-row>

  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      menu: (state) => state.tab.menu
    }),
  },
  data () {
    return {
      data:{
        memberInfo:{
          size:0,
        },
        crontabInfo:{
          size:0,
        },
        actionInfo:{
          list:[],
        },
      },
      loading:false,
    }
  },
  mounted(){
    this.getActionListMini()
  },
  methods: {
    // 获取登录日志列表
    getActionListMini(){
      let that = this;
      that.loading = true;
      this.$http.GET(
        "/v1/panel/getInfo",
        function (ret) {
          if (ret.errcode == 0) {
            that.data = ret.data || [];
            that.loading = false;
          } else if (ret.errcode >= 1) {
            that.loading = false;
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.loading = false;
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 跳转安装
    link (name) {
      let menu = this.menu
      let item ={}
      for(let o in menu){
        if(menu[o].name == name){
          item = menu[o]
        }
        let children = menu[o].children
        for(let i in children){
          if(children[i].name == name){
            item = children[i]
          }
          let children2 = children[i].children
          for(let s in children2){
            if(children2[s].name == name){
              item = children2[s]
            }
          }
        }
      }
      const data = {
        item: item,
        asideMenu: this.menu
      }
      this.$store.commit('selectMenu', data)
      this.$router.push({ name: item.name })
    }
  },
  watch: {
  },
  components: {
  }
}
</script>
<style scoped lang="scss">
.box-card{
  a{
    color: #2d8cf0;
    cursor: pointer;
  }
}
.item{
  p {
    padding: 0;
    margin: 0;
    font-size: 13px;
  }
}
</style>
